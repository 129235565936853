import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { getCurrentDayInterval, isValidDateInterval } from '../utils/datetime'
import { useAuth, useClient } from './auth-context'
const SelectionContextState = createContext()
const SelectionContextUpdater = createContext()

const useSelectionContextState = () => {
  const context = useContext(SelectionContextState)
  if (context === undefined) throw new Error('SelectionContextState was used outside of its Provider')
  return context
}

const useSelectionContextUpdater = () => {
  const context = useContext(SelectionContextUpdater)
  if (context === undefined) throw new Error('SelectionContextUpdater was used outside of its Provider')
  return context
}

function SelectionContextProvider({ children }) {
  const [forceReloadOrganizationChange, setForceReloadOrganizationChange] = useState(0)
  const [activeCar, setActiveCar] = useState(null)
  const [activeHistoryCar, setActiveHistoryCar] = useState(null)
  const [timeInterval, setTimeInterval] = useState(null)
  const [selectedCars, setSelectedCars] = useState([])
  const [carStatus, setCarStatus] = useState([])
  const [timeIntervalOpen, setTimeIntervalOpen] = useState(false)
  const [waitTimeLimit, setWaitTimeLimit] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedMarkersTimeInterval, setSelectedMarkersTimeInterval] = useState({ start: null, end: null })
  const [playbackStatus, setPlaybackStatus] = useState({ status: 'stop', play_speed: 2 })
  const [selectedLayers, setSelectedLayers] = useState({ manualRoute: true, realRoute: false, mixedRoute: false })
  const [loadedPeriods, setLoadedPeriods] = useState({ timeInterval: null })
  const [editAllowed, setEditAllowed] = useState(false)
  const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false)
  const [flyTo, setFlyTo] = useState(null)
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [cars, setCars] = useState([])
  const [manualRouteSettings, setManualRouteSettings] = useState({
    maxMarkerCount: 70,
    allowAdd: false,
    showMarkers: true,
  })
  const [forceManualRouteReload, setForceManualRouteReload] = useState(false)
  const [forceMixedRouteReload, setForceMixedRouteReload] = useState({ map: false, source: false })
  const [setRouteSidebarSelectedSection, setSetRouteSidebarSelectedSection] = useState('cars')
  const [mixedHistory, setMixedHistory] = useState(null)
  const [tailSettings, setTailSettings] = useState({ open: false, seconds: 0 })
  const [tailDataPromise, setTailDataPromise] = useState([])
  const [snackBarData, setSnackBarData] = useState(null)
  const [tags, setTags] = useState([])
  const [showTooltips, setShowTooltips] = useState(true)
  const [googleMode, setGoogleMode] = useState(null)
  const [routeModeData, setRouteModeData] = useState(null)
  const [polygons, setPolygons] = useState([])
  const [selectedPolygons, setSelectedPolygons] = useState({})
  const [editablePolygon, setEditablePolygon] = useState(null)
  const [poiTags, setPoiTags] = useState([])
  const [videoDialog, setvideoDialog] = useState(null)

  const { user } = useAuth()
  const client = useClient()

  useEffect(() => {
    if (user?.type && !user?.pending_2fa) {
      client('cars/list', { data: getCurrentDayInterval() })
        .then((res) => {
          if (res.error) {
            //ToDO errors
          } else if (res.result) {
            setCars(res.result)
          } else {
            setCars([])
          }
        })
        .catch((error) => {
          console.log(error)
        })

      client('tags/list')
        .then((res) => {
          console.log('tags/list res', res)
          if (res.error) {
            console.log(res.error)
          }
          setTags(res.result ?? [])
        })
        .catch((error) => {
          console.log(error)
        })

      client('polygons/list')
        .then((res) => {
          console.log('polygons/list res', res)
          if (res.error) {
            console.log(res.error)
          }
          setPolygons(res.result ?? [])
        })
        .catch((error) => {
          console.log(error)
        })
      setSelectedPolygons({})

      client('poi-tags/list')
        .then((res) => {
          console.log('poitags/list res', res)
          if (res.error) {
            console.log(res.error)
          }
          setPoiTags(res.result ?? [])
        })
        .catch((error) => {
          console.log(error)
        })
    }

    setEditablePolygon(null)
  }, [client, forceReloadOrganizationChange, user?.type, user?.pending_2fa])

  const incForceReloadOrganizationChange = useCallback(
    () => setForceReloadOrganizationChange(forceReloadOrganizationChange + 1),
    [forceReloadOrganizationChange],
  )
  const changeActiveCar = useCallback(
    (car) => {
      if (!isValidDateInterval(timeInterval)) setTimeIntervalOpen(true)
      setActiveCar(car)
    },
    [timeInterval],
  )
  const changeTimeInterval = useCallback((tival) => {
    //console.log('timeInterval: ', tival)
    setTimeInterval(tival)
  }, [])
  const changeActiveHistoryCar = useCallback((v) => setActiveHistoryCar(v), [])
  const changeSelectedCars = useCallback((v) => setSelectedCars(v), [])
  const changeTimeIntervalOpen = useCallback((v) => setTimeIntervalOpen(v), [])
  const changeWaitTimeLimit = useCallback((v) => setWaitTimeLimit(v), [])
  const changeIsLoading = useCallback((v) => setIsLoading(v), [])
  const changeSelectedMarkersTimeInterval = useCallback((v) => setSelectedMarkersTimeInterval(v), [])
  const changePlaybackStatus = useCallback((v) => setPlaybackStatus(v), [])
  const changeManualRouteSettings = useCallback((v) => setManualRouteSettings(v), [])
  const changeSelectedLayers = useCallback((v) => setSelectedLayers(v), [])
  const changeLoadedPeriods = useCallback(
    (changes) => setLoadedPeriods({ ...loadedPeriods, ...changes }),
    [loadedPeriods],
  )
  const changeEditAllowed = useCallback((v) => setEditAllowed(v), [])
  const changeSidebarMobileOpen = useCallback((v) => setSidebarMobileOpen(v), [])
  const changeCarStatus = useCallback((v) => setCarStatus(v), [])
  const changeFlyTo = useCallback((v) => setFlyTo(v), [])
  const changeSelectedOrganization = useCallback((v) => setSelectedOrganization(v), [])
  const changeCars = useCallback((v) => setCars(v), [])
  const changeForceManualRouteReload = useCallback((v) => setForceManualRouteReload(v), [])
  const changeForceMixedRouteReload = useCallback((v) => setForceMixedRouteReload(v), [])
  const changeSetRouteSidebarSelectedSection = useCallback((v) => setSetRouteSidebarSelectedSection(v), [])
  const changeMixedHistory = useCallback((v) => setMixedHistory(v), [])
  const changeTailSettings = useCallback((v) => setTailSettings(v), [])
  const changeTailDataPromise = useCallback((v) => setTailDataPromise(v), [])
  const changeSnackBarData = useCallback((v) => setSnackBarData(v), [])
  const changeTags = useCallback((v) => setTags(v), [])
  const changeSelectAll = useCallback(
    (v) => {
      if (v) setSelectedCars([...cars.map((car) => car.id)])
      else setSelectedCars([])
    },
    [cars],
  )
  const toogleShowTooltips = useCallback(() => setShowTooltips((old) => !old), [])
  const changeGoogleMode = useCallback((v) => setGoogleMode(v), [])
  const changeRouteModeData = useCallback((v) => setRouteModeData(v), [])
  const changePolygons = useCallback((v) => setPolygons(v), [])
  const changeSelectedPolygons = useCallback((v) => setSelectedPolygons(v), [])
  const changeEditablePolygon = useCallback((v) => setEditablePolygon(v), [])
  const changePoiTags = useCallback((v) => setPoiTags(v), [])
  const changeVideoDialog = useCallback((v) => setvideoDialog(v), [])

  const doRefresh = useCallback((type) => {
    switch (type) {
      case '/set-route':
      case '/main':
        setActiveCar(null)
        setSelectedCars([])
        setSidebarMobileOpen(false)
        setFlyTo(null)
        break
      default:
        //full reload
        setForceReloadOrganizationChange(0)
        setActiveCar(null)
        setActiveHistoryCar(null)
        setTimeInterval(null)
        setSelectedCars([])
        setCarStatus([])
        setTimeIntervalOpen(false)
        setWaitTimeLimit(0)
        setIsLoading(false)
        setSelectedMarkersTimeInterval({ start: null, end: null })
        setPlaybackStatus({ status: 'stop', play_speed: 2 })
        setSelectedLayers({ manualRoute: true, realRoute: false, mixedRoute: false })
        setLoadedPeriods({ timeInterval: null })
        setEditAllowed(false)
        setSidebarMobileOpen(false)
        setFlyTo(null)
        setSelectedOrganization(null)
        setCars([])
        setManualRouteSettings({
          maxMarkerCount: 70,
          allowAdd: false,
          showMarkers: true,
        })
        setForceManualRouteReload(false)
        setForceMixedRouteReload(false)
        setSetRouteSidebarSelectedSection('cars')
        setMixedHistory(null)
        setTailSettings({ open: false, seconds: 0 })
        setTailDataPromise([])
        setTags([])
        setPolygons([])
        setSelectedPolygons({})
        setEditablePolygon(null)
        setPoiTags([])
        break
    }
  }, [])

  return (
    <SelectionContextState.Provider
      value={{
        activeCar,
        timeInterval,
        selectedCars,
        timeIntervalOpen,
        waitTimeLimit,
        isLoading,
        selectedMarkersTimeInterval,
        playbackStatus,
        manualRouteSettings,
        selectedLayers,
        loadedPeriods,
        editAllowed,
        sidebarMobileOpen,
        carStatus,
        flyTo,
        selectedOrganization,
        activeHistoryCar,
        cars,
        forceManualRouteReload,
        forceMixedRouteReload,
        setRouteSidebarSelectedSection,
        mixedHistory,
        tailSettings,
        tailDataPromise,
        snackBarData,
        tags,
        showTooltips,
        googleMode,
        routeModeData,
        polygons,
        selectedPolygons,
        editablePolygon,
        poiTags,
        videoDialog,
      }}
    >
      <SelectionContextUpdater.Provider
        value={{
          incForceReloadOrganizationChange,
          changeActiveCar,
          changeTimeInterval,
          changeSelectedCars,
          changeTimeIntervalOpen,
          changeWaitTimeLimit,
          changeIsLoading,
          changeSelectedMarkersTimeInterval,
          changePlaybackStatus,
          changeManualRouteSettings,
          changeSelectedLayers,
          changeLoadedPeriods,
          changeEditAllowed,
          changeSidebarMobileOpen,
          changeCarStatus,
          changeFlyTo,
          changeSelectedOrganization,
          changeActiveHistoryCar,
          changeCars,
          changeForceManualRouteReload,
          changeForceMixedRouteReload,
          doRefresh,
          changeSetRouteSidebarSelectedSection,
          changeMixedHistory,
          changeTailSettings,
          changeTailDataPromise,
          changeSnackBarData,
          changeTags,
          changeSelectAll,
          toogleShowTooltips,
          changeGoogleMode,
          changeRouteModeData,
          changePolygons,
          changeSelectedPolygons,
          changeEditablePolygon,
          changePoiTags,
          changeVideoDialog,
          //changeStatisticsTimers
        }}
      >
        {children}
      </SelectionContextUpdater.Provider>
    </SelectionContextState.Provider>
  )
}

export { SelectionContextProvider, useSelectionContextState, useSelectionContextUpdater }
