import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
var { version } = require('../../package.json')

function Version() {
  return (
    <Box
      sx={{
        display: 'flex',
        zIndex: 1000,
        left: 5,
        bottom: 5,
        position: 'fixed',
        '&>span': {
          fontSize: '0.5rem',
        },
      }}
    >
      <Typography variant="caption">{version}</Typography>
    </Box>
  )
}

export default Version
