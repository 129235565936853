import { Button, DialogActions, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import { useAuth, useClient } from './context/auth-context'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LanguageContext } from './context/language-context'

function Login() {
  const [account, setAccount] = React.useState({
    username: '',
    password: '',
  })
  const [errors, setErrors] = React.useState({}) //field errors
  const [error, setError] = React.useState('') //global login error
  const [need2FA, setNeed2FA] = React.useState(false)
  const { user, login, logout, reauthenticate } = useAuth()
  let navigate = useNavigate()
  const client = useClient()
  const { t, i18n } = useTranslation()
  const {lng} = useParams()
  const { locale, setLocale } = useContext(LanguageContext)

  React.useEffect(() => {
    if(lng && lng!==locale && ['en','lv','de','es','fi','no'].includes(lng)){
      setLocale(lng)
      i18n.changeLanguage(lng)
    }
  }, [lng, locale, setLocale, i18n])
  

  React.useEffect(() => {
    if (
      user &&
      !user.pending_2fa &&
      (user.type === 'superadmin' ||
        user.type === 'manager' ||
        user.type === 'admin' ||
        user.type === 'user' ||
        user.type === 'limiteduser')
    )
      navigate('/')

    if (user?.pending_2fa) setNeed2FA(true)
  }, [user, navigate])

  React.useEffect(() => {
    let timeoutID
    if (need2FA) {
      function check2fa() {
        client('auth/check-2fa')
          .then((res) => {
            if (res.error) {
              //setErrors({...errors,...res.error});
            } else if (res.done === 'OK') {
              if (res.result === 'active') {
                reauthenticate()
                  .then(() => navigate('/'))
                  .catch((err) => {
                    console.error('reauthenticate err: ', err)
                    //ToDo - error
                  })
              } else if (res.result === 'pending') timeoutID = setTimeout(check2fa, 2000)
              else if (res.result === 'inactive') navigate('/')
              else {
                //ToDo error
              }
            } else {
              //ToDo errors
            }
          })
          .catch(() => {
            //ToDo - maybe try cound
            timeoutID = setTimeout(check2fa, 5000)
          })
      }
      check2fa()
    }
    clearTimeout(timeoutID)

    return () => {
      clearTimeout(timeoutID)
    }
  }, [client, navigate, need2FA, reauthenticate])

  function handleChange(e) {
    setAccount({ ...account, [e.target.name]: e.target.value })
  }

  async function handleCancel() {
    await logout()
    setNeed2FA(false)
    //reauthenticate()
  }

  function doLogin() {
    setError('')
    setErrors({})
    login(account).then((r) => {
      //console.log('login res: ', r)
      if (!r || r.error) {
        setError(r?.error ?? { data: 'operation_failed' })
      } else {
        if (r.need_2fa) setNeed2FA(true)
        else navigate('/', { replace: true })
      }
    })
  }

  function translateError(error) {
    const key = Object.keys(error)[0]
    const texts = {
      data: {
        missing_data: t('login.err.data.missingData'),
        account_not_found: t('login.err.data.accountNotFound'),
        operation_failed: t('login.err.data.operationFailed'),
      },
      username: {
        missing: t('login.err.username.missing'),
      },
      password: {
        missing: t('login.err.password.missing'),
        account_not_found_or_wrong_password: t('login.err.password.accountNotFoundOrWrongPassword'),
      },
    }
    const res = key ? texts[key][error[key]] : null
    return res ?? ''
  }

  return (
    <div className="center-screen">
      <Paper elevation={3} sx={{ maxWidth: 345 }}>
        {user && user.type && !user.pending_2fa && (
          <Button onClick={logout} size="large">
            {t('login.logout')}
          </Button>
        )}
        {(!user || !user.type || user.pending_2fa) && (
          <Box
            sx={{
              p: 2,
              '& .MuiTextField-root': { mt: 2, width: '100%' },
            }}
          >
            <Typography variant="h6" align="center">
              {t('login.login')}
            </Typography>
            <TextField
              name="username"
              value={account.username}
              error={errors.username ? true : false}
              onChange={handleChange}
              label={t('login.username')}
              helperText={errors.username ? errors.username : null}
              disabled={need2FA}
            />
            <TextField
              name="password"
              hinttext={t('login.password')}
              floatinglabeltext="Password"
              type="password"
              value={account.password}
              error={errors.password ? true : false}
              onChange={handleChange}
              label={t('login.password')}
              helperText={errors.password ? errors.password : null}
              onKeyDown={(e) => {
                if (e.key === 'Enter') doLogin()
              }}
              disabled={need2FA}
            />
            {error && (
              <Typography variant="caption" align="center" color="red">
                {translateError(error)}
              </Typography>
            )}
            <DialogActions>
              <Button onClick={doLogin} size="large" disabled={need2FA}>
                {t('login.login')}
              </Button>
            </DialogActions>
            {need2FA && (
              <>
                <Typography align="center">{t('login.waitingFor2FA')}</Typography>
                <Stack>
                  <Button variant="contained" onClick={handleCancel} size="large">
                    {t('login.cancel')}
                  </Button>
                </Stack>
              </>
            )}
          </Box>
        )}
      </Paper>
    </div>
  )
}

export { Login }
