import { Typography } from '@mui/material'
import React from 'react'
import { getNotificationText, getShortNotificationText } from '../../../utils/textFormating'

function NotificationShortText({data, object}) {
  let [objectTitle,type,time] =getShortNotificationText(data, object)
  return (
    <Typography>{objectTitle}<b>{type}</b>{time}</Typography>
  )
}

function NotificationText({data, object}) {
  let [objectTitle,type,time] =getNotificationText(data, object)
  return (
    <Typography>{objectTitle}<b>{type}</b>{time}</Typography>
  )
}

export {NotificationShortText,NotificationText}