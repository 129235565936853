import { timestampToLocalString } from "./datetime"

function getCarTitle(car) {
    if(!car)
        return null
    if(!car.registration_number)
        return car.name
    return `${car.registration_number} (${car.name})`
}

function getShortNotificationText(n, object) {
    let val=n?.value==='true'?'':(n?.value?` ${n.value}`:'')
    return [
      `${object ? object + ', ' : ''} `,
      `${n?.code} ${n.sub_key}${val}`,
      `, ${timestampToLocalString(n?.event_time)}`,
    ]
}

function getNotificationText(n, object) {
    let val=n?.value==='true'?'':(n?.value?` ${n.value}`:'')
    return [
      `${n?.name ? n?.name + ' - ' : ''}${object ? object + ', ' : ''}`,
      `${n?.code} ${n.sub_key}${val}`,
      `, ${timestampToLocalString(n?.event_time)}`,
    ]
}

export {getCarTitle, getShortNotificationText, getNotificationText}