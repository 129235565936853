import { createContext } from 'react'
import lvLocale from 'date-fns/locale/lv'
//import ruLocale from "date-fns/locale/ru";
import enLocale from 'date-fns/locale/en-GB'

//locale.options.weekStartsOn = 1
const localeMap = {
  lv: { ...lvLocale, options: { ...lvLocale.options, weekStartsOn: 1 } },
  en: { ...enLocale, options: { ...enLocale.options, weekStartsOn: 1 } },
  //ru: {...ruLocale, options: {...ruLocale.options, weekStartsOn : 1}},
}

const LanguageContext = createContext() //'en'

export { LanguageContext, localeMap }
