import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelectionContextState } from '../context/selection-context';


function BlockWhileProgress({children, isLoading}) {
  const {isLoading:isLoadingState} = useSelectionContextState()
  const isLoadingLocal= isLoading !==undefined?(isLoadingState?true:isLoading):isLoadingState
  return isLoadingLocal ? (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex:1000
      }}
    >
      {children}
    </Box>
  ) : null
}

function BlockWhileCircularProgressWithLabel(props) {
  const circularProps ={...props}
  delete circularProps.isLoading
  return (
    <BlockWhileProgress {...props}>
      {props.value && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="white">
            {`${props.value ? Math.round(props.value) : 0}%`}
          </Typography>
        </Box>
      )}
      <CircularProgress variant={props.value ? 'determinate' : 'indeterminate'} {...circularProps} />
    </BlockWhileProgress>
  )
}

export {BlockWhileProgress, BlockWhileCircularProgressWithLabel}