import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { NotFound } from '../not-found'
import { Login } from '../login'

function Pending2FARoutes() {
  //console.log('pending2FARoutes')
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/login" />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/lng/:lng" element={<Login />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  )
}

export default Pending2FARoutes
