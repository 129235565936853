import React, { useState, useEffect } from 'react'
import {
  Autocomplete, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup,
  FormHelperText, FormLabel, IconButton, InputLabel, Paper, Radio, RadioGroup, Select, Stack,
  Switch, TextareaAutosize, TextField, Typography
} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material'
import { isValidDate } from '../utils/datetime'
import styled from '@emotion/styled'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box } from '@mui/system'
import { green } from '@mui/material/colors'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

function TextInput(props) {
  const [value, setValue] = useState('')
  const [errorCode, setErrorCode] = useState('')
  const { t } = useTranslation('translation', { keyPrefix: 'otherComponents' })

  useEffect(() => {
    if (props.initialVal !== undefined) setValue(props.initialVal ?? '')
    else {
      if (props.defaultInitialVal !== undefined) setValue(props.defaultInitialVal ?? '')
    }
  }, [props.initialVal, props.defaultInitialVal])

  useEffect(() => {
    setErrorCode(props.errorCode)
  }, [props.errorCode])
  //ToDo - Changing props.errorCode outside of component, couses to render this component 2 times

  function validateText(value, required) {
    if (required && (!value || value === '')) return t('err.required')
    if (value?.length > 200) return t('err.textIsTooLong')
  }

  function handleChange(e) {
    let val = e.target.value
    switch (props.numberType) {
      case 'integer':
        val = parseInt(val)
        break
      case 'decimal2d':
        val = val ? Number(Number(val).toFixed(2)) : ''
        break
      default:
        break
    }

    let err
    if (props.validate) err = props.validate(val, e.target.name)
    else err = validateText(val, e.target.required)

    if (errorCode !== err) setErrorCode(err)
    setValue(val)

    if (props.handleChange) {
      props.handleChange(e, err)
    }
  }

  function handleBlur(e) {
    if (props.onBlur) props.onBlur(e, errorCode, e.target.required ? false : true, value)
  }

  const localProps = { ...props }
  ;[
    'validate',
    'initialVal',
    'errorCode',
    'success',
    'onBlur',
    'fullWidth',
    'handleChange',
    'readOnly',
    'defaultInitialVal',
    'step',
    'numberType',
  ].map((p) => delete localProps[p])



  return (
    <TextField
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      error={errorCode ? true : false}
      helperText={errorCode ? errorCode : props.success ? props.success : ''}
      color={props.success ? 'success' : 'primary'}
      focused={props.success ? true : false}
      size="small"
      fullWidth={props.fullWidth}
      {...localProps}
      inputProps={{ readOnly: props.readOnly, step: props.step ?? '1'}}
    />
  )
}

function EmailInput(props) {
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      ? null
      : i18next.t('otherComponents.err.invalidEmail')
  }

  return <TextInput validate={validateEmail} {...props} />
}

function SelectInput(props) {
  const localProps = { ...props }
  ;['success', 'errorCode', 'onBlur', 'value', 'fullWidth', 'sx', 'textColor', 'background'].map(
    (p) => delete localProps[p],
  )

  let inputLabelId = props.name + '-label'
  let elementColor = props.success ? 'success' : 'primary'

  return (
    <FormControl
      fullWidth={props.fullWidth}
      required={props.required}
      error={props.errorCode ? true : null}
      sx={props.sx}
    >
      <InputLabel id={inputLabelId} size="small" color={elementColor}>
        {props.label}
      </InputLabel>
      <Select
        labelId={inputLabelId}
        size="small"
        color={elementColor}
        value={props.value ?? ''}
        {...localProps}
        sx={{ color: props.textColor, background: props.background }}
      >
        {props.children}
      </Select>

      {props.success && (
        <FormHelperText sx={{ textAlign: 'center', color: 'success.main' }}>{props.success}</FormHelperText>
      )}
      {props.errorCode && (
        <FormHelperText sx={{ textAlign: 'left', color: 'error.main' }}>{props.errorCode}</FormHelperText>
      )}
    </FormControl>
  )
}

function SearchInput(props) {
  const localProps = { ...props }
  ;['handleSearch', 'handleAdd'].map((p) => delete localProps[p])

  return (
    <Paper component="form" sx={{ my: 1, display: 'flex', flexGrow: 1, height: 40 }}>
      <InputBase sx={{ ml: 1, flex: 1 }} {...localProps} />
      {props.handleSearch ? (
        <IconButton sx={{ p: '10px' }} aria-label="search" onClick={props.handleSearch}>
          <SearchIcon />
        </IconButton>
      ) : null}
      {props.handleAdd ? (
        <>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton sx={{ p: '10px' }} onClick={props.handleAdd}>
            <AddIcon />
          </IconButton>
        </>
      ) : null}
    </Paper>
  )
}

function DateInput(props) {
  const [dateValue, setDateValue] = useState(null)
  const [temporaryDateValue, setTemporaryDateValue] = useState(null)
  const [errorCode, setErrorCode] = useState('')

  useEffect(() => {
    if (props.initialVal) {
      setDateValue(props.initialVal)
      setTemporaryDateValue(props.initialVal)
    }
  }, [props.initialVal])

  useEffect(() => {
    setErrorCode(props.errorCode)
  }, [props.errorCode])

  function handleAccept(newValue) {
    if (newValue === null) {
      //user closes picker without picking a value
      return
    }
    if (isValidDate(newValue) && (!props.minDate || props.minDate <= newValue)) {
      if (dateValue !== newValue) {
        setDateValue(newValue)
        setErrorCode(null)
        if (props.onAccept) {
          props.onAccept(props.name, newValue)
        }
      }
    } else {
      setErrorCode(i18next.t('otherComponents.err.invalidDate'))
      if (props.onError) {
        props.onError(props.name, newValue)
      }
    }
  }

  function handleOnChange(newValue) {
    setTemporaryDateValue(newValue)
    if (newValue === null) {
      //user closes picker without picking a value
      return
    }
    if (isValidDate(newValue) && (!props.minDate || props.minDate <= newValue)) {
      setErrorCode(null)
    } else {
      setErrorCode(i18next.t('otherComponents.err.invalidDate'))
    }
  }

  function handleBlur() {
    if (temporaryDateValue !== dateValue) {
      if (temporaryDateValue === null) {
        if (props.onEdit) {
          props.onEdit(props.name, temporaryDateValue)
        }
      } else {
        if (isValidDate(temporaryDateValue) && (!props.minDate || props.minDate <= temporaryDateValue)) {
          setDateValue(temporaryDateValue)
          setErrorCode(null)
          if (props.onEdit) {
            props.onEdit(props.name, temporaryDateValue)
          }
        } else {
          setErrorCode(i18next.t('otherComponents.err.invalidDate'))
          if (props.onError) {
            props.onError(props.name, temporaryDateValue)
          }
        }
      }
    }
  }

  const localProps = { ...props }
  ;['initialVal', 'success', 'errorCode', 'onAccept', 'onError', 'required', 'name', 'sx', 'size', 'fullWidth'].map(
    (p) => delete localProps[p],
  )

  return (
    <FormControl fullWidth={props.fullWidth} sx={props.sx}>
      <DatePicker
        {...localProps}
        value={dateValue}
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        onAccept={handleAccept}
        onChange={handleOnChange}
        fullWidth={props.fullWidth}
        color={props.success ? 'success' : 'primary'}
        renderInput={(params) => (
          <TextField required={props.required} size={props.size ?? 'small'} {...params} onBlur={handleBlur} />
        )}
      />
      {props.success && (
        <FormHelperText sx={{ textAlign: 'center', color: 'success.main' }}>{props.success}</FormHelperText>
      )}
      {errorCode && <FormHelperText sx={{ textAlign: 'left', color: 'error.main' }}>{errorCode}</FormHelperText>}
    </FormControl>
  )
}

function TimeInput(props) {
  const [timeValue, setTimeValue] = useState(null)
  const [temporaryValue, setTemporaryValue] = useState(null)
  const [errorCode, setErrorCode] = useState('')

  useEffect(() => {
    if (props.initialVal) {
      setTimeValue(props.initialVal)
      setTemporaryValue(props.initialVal)
    }
  }, [props.initialVal])

  useEffect(() => {
    setErrorCode(props.errorCode)
  }, [props.errorCode])

  function handleAccept(newValue) {
    if (isValidDate(newValue)) {
      if (timeValue !== newValue) {
        setTimeValue(newValue)
        setErrorCode(null)
        if (props.onAccept) {
          props.onAccept(props.name, newValue)
        }
      }
    } else {
      setErrorCode(i18next.t('otherComponents.err.invalidTime'))
      if (props.onError) {
        props.onError(props.name, newValue)
      }
    }
  }

  function handleOnChange(newValue) {
    setTemporaryValue(newValue)
    if (isValidDate(newValue)) {
      setErrorCode(null)
    } else {
      setErrorCode(i18next.t('otherComponents.err.invalidTime'))
    }
  }

  function handleBlur() {
    if (temporaryValue !== timeValue) {
      if (isValidDate(temporaryValue)) {
        setTimeValue(temporaryValue)
        setErrorCode(null)
        if (props.onEdit) {
          props.onEdit(props.name, temporaryValue)
        }
      } else {
        setErrorCode(i18next.t('otherComponents.err.invalidTime'))
        if (props.onError) {
          props.onError(props.name, temporaryValue)
        }
      }
    }
  }

  const localProps = { ...props }
  ;['initialVal', 'success', 'errorCode', 'onAccept', 'onError', 'required', 'name', 'sx', 'small'].map(
    (p) => delete localProps[p],
  )

  return (
    <FormControl sx={props.sx}>
      <TimePicker
        {...localProps}
        value={timeValue}
        ampm={false}
        views={props?.hideSeconds !== undefined ? ['hours', 'minutes'] : ['hours', 'minutes', 'seconds']}
        inputFormat={props?.hideSeconds !== undefined ? 'HH:mm' : 'HH:mm:ss'}
        mask={props?.hideSeconds !== undefined ? '__:__' : '__:__:__'}
        onAccept={handleAccept}
        onChange={handleOnChange}
        renderInput={(params) => (
          <TextField size={props.size ?? 'small'} required={props.required} {...params} onBlur={handleBlur} />
        )}
      />
      {props.success && (
        <FormHelperText sx={{ textAlign: 'center', color: 'success.main' }}>{props.success}</FormHelperText>
      )}
      {errorCode && <FormHelperText sx={{ textAlign: 'left', color: 'error.main' }}>{errorCode}</FormHelperText>}
    </FormControl>
  )
}

function DateTimeInput(props) {
  const [value, setValue] = useState(null)
  const [temporaryValue, setTemporaryValue] = useState(null)
  const [errorCode, setErrorCode] = useState('')
  //const [lastView, setlastView] = useState(null)

  useEffect(() => {
    if (props.initialVal !== undefined) {
      console.log('props.initialVal: ', props.initialVal)
      setValue(props.initialVal)
      setTemporaryValue(props.initialVal)
    }
  }, [props.initialVal])

  useEffect(() => {
    setErrorCode(props.errorCode)
  }, [props.errorCode])

  function handleOnChange(newValue) {
    if (newValue === null) {
      return
    }
    if (isValidDate(newValue)) {
      if (value !== newValue) {
        setTemporaryValue(newValue)
        //console.log('change newValue: ', newValue)
        setErrorCode(null)
        if (props.onChange) {
          props.onChange(newValue, props.name)
        }
      }
    } else {
      setErrorCode(i18next.t('otherComponents.err.invalidTime'))
      if (props.onError) {
        props.onError(newValue, props.name)
      }
    }
  }

  function handleOnAccept(val) {
    let valueToUpdate = val ?? temporaryValue
    if (!value && props.views?.length === 1 && props.views?.[0] === 'day') {
      if (props.name === 'startTime') valueToUpdate.setHours(0, 0, 0, 0)
      if (props.name === 'endTime') valueToUpdate.setHours(23, 59, 59, 999)
    }
    //setlastView(null)
    setValue(valueToUpdate)
    if (props.onAccept) {
      props.onAccept(valueToUpdate, props.name)
    }
  }

  function handleBlur() {
    if (temporaryValue !== value) {
      if (isValidDate(temporaryValue)) {
        setValue(temporaryValue)
        setErrorCode(null)
        if (props.onBlur) {
          props.onBlur(temporaryValue, props.name)
        }
      } else {
        setErrorCode(i18next.t('otherComponents.err.invalidTime'))
        if (props.onError) {
          props.onError(temporaryValue, props.name)
        }
      }
    }
  }

  // function handleViewChange(view){
  //   console.log('view: ', view, temporaryValue)
  //   setlastView(view)
  // }

  const localProps = { ...props }
  ;[
    'initialVal',
    'success',
    'errorCode',
    'onChange',
    'onAccept',
    'onBlur',
    'onError',
    'required',
    'name',
    'sx',
    'fullWidth',
  ].map((p) => delete localProps[p])

  return (
    <FormControl fullWidth={props.fullWidth} sx={props.sx}>
      <DateTimePicker
        {...localProps}
        renderInput={(params) => (
          <TextField {...params} size="small" required={props.required} helperText={null} onBlur={handleBlur} />
        )}
        ampm={false}
        inputFormat="dd.MM.yyyy HH:mm:ss"
        mask="__.__.____ __:__:__"
        value={value}
        fullWidth={props.fullWidth}
        onChange={handleOnChange}
        onAccept={handleOnAccept}
        //onViewChange={handleViewChange}
      />
      {props.success && (
        <FormHelperText sx={{ textAlign: 'center', color: 'success.main' }}>{props.success}</FormHelperText>
      )}
      {errorCode && <FormHelperText sx={{ textAlign: 'left', color: 'error.main' }}>{errorCode}</FormHelperText>}
    </FormControl>
  )
}

function AutocompleteInput(props) {
  const localProps = { ...props }
  ;['name', 'label', 'onSelect', 'success', 'errorCode', 'onBlur', 'freeSolo', 'required', 'onRemoveOption'].map(
    (p) => delete localProps[p],
  )

  function handleChange(object, value, reason) {
    if (props.onSelect) {
      props.onSelect(props.name, value, reason)
    }
  }

  function handleBlur(object) {
    if (props.onBlur) {
      props.onBlur(props.name, object.target.defaultValue)
    }
  }

  return (
    <>
      <Autocomplete
        {...localProps}
        disablePortal
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        freeSolo={props.freeSolo ? true : false}
        onChange={handleChange}
        renderOption={(renderProps, option, { selected }) => (
          <li {...renderProps}>
            <Box display="flex" flexGrow={1}>
              <Typography>{props.getOptionLabel(option)}</Typography>
            </Box>
            {props.onRemoveOption && props.getOptionLabel(props.value) !== props.getOptionLabel(option) && (
              <IconButton
                aria-label="delete"
                sx={{ textAlign: 'right' }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (props.onRemoveOption) {
                    props.onRemoveOption(option)
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={props.label} size="small" onBlur={handleBlur} required={props.required} />
        )}
      />
      {props.success && (
        <FormHelperText sx={{ textAlign: 'center', color: 'success.main' }}>{props.success}</FormHelperText>
      )}
      {props.errorCode && (
        <FormHelperText sx={{ textAlign: 'left', color: 'error.main', pl: '12px' }}>{props.errorCode}</FormHelperText>
      )}
    </>
  )
}

function CheckboxInput(props) {
  const localProps = { ...props }
  ;['label'].map((p) => delete localProps[p])

  return (
    <FormGroup>
      <FormControlLabel
        label={
          <Typography color={props.disabled ? 'text.disabled' : 'text.primary'} sx={props.sx}>
            {props.label}
          </Typography>
        }
        control={<Checkbox {...localProps} />}
      />
    </FormGroup>
  )
}

function SwitchInput(props) {
  const localProps = { ...props }
  ;['name', 'color', 'labelOff', 'labelOn', 'checked', 'onChange'].map((p) => delete localProps[p])

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ m: 0, p: 0 }}>
      <Typography color={props.color}>{props.labelOff}</Typography>
      <AntSwitch
        checked={props.checked}
        onChange={(e) => {
          if (props.onChange) props.onChange(e.target.checked, props.name)
        }}
      />
      <Typography color={props.color}>{props.labelOn}</Typography>
    </Stack>
  )
}

function TextareaInput(props) {
  const [value, setValue] = useState('')
  const [errorCode, setErrorCode] = useState('')
  const localProps = { ...props }
  ;['validate', 'initialVal', 'errorCode', 'success', 'onBlur', 'handleChange', 'readOnly', 'defaultInitialVal'].map(
    (p) => delete localProps[p],
  )

  useEffect(() => {
    if (props.initialVal) setValue(props.initialVal)
    else {
      if (props.defaultInitialVal !== undefined) setValue(props.defaultInitialVal)
    }
  }, [props.initialVal, props.defaultInitialVal])

  useEffect(() => {
    setErrorCode(props.errorCode)
  }, [props.errorCode])
  //ToDo - Changing props.errorCode outside of component, couses to render this component 2 times

  function validateText(value, required) {
    if (required && (!value || value === '')) return i18next.t('otherComponents.err.required')
  }

  function handleChange(e) {
    let err
    if (props.validate) err = props.validate(e.target.value, e.target.name)
    else err = validateText(e.target.value, e.target.required)

    if (errorCode !== err) setErrorCode(err)
    setValue(e.target.value)

    if (props.handleChange) {
      props.handleChange(e)
    }
  }

  function handleBlur(e) {
    if (props.onBlur) props.onBlur(e, errorCode, e.target.required ? false : true)
  }

  return (
    <>
      <TextareaAutosize
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        error={errorCode}
        color={props.success ? 'success' : 'primary'}
        size="small"
        {...localProps}
      />
      {props.success && (
        <FormHelperText sx={{ textAlign: 'center', color: 'success.main' }}>{props.success}</FormHelperText>
      )}
      {props.errorCode && (
        <FormHelperText style={{ marginLeft: 12 }} sx={{ textAlign: 'left', color: 'error.main' }}>
          {props.errorCode}
        </FormHelperText>
      )}
    </>
  )
}

function RadioButtonsGroup(props) {
  return (
    <FormControl sx={{ ...props.sx, display: 'block' }} disabled={props.disabled}>
      {props.title && <FormLabel sx={{ color: 'text.primary' }}>{props.title}</FormLabel>}
      <RadioGroup name={props.name} value={props.value} onChange={props.onChange}>
        {props.buttons.map((button, index) => (
          <FormControlLabel
            key={index}
            value={button.value}
            label={button.label}
            control={<Radio sx={props.buttonSx} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

function Spinner(props) {
  const { sx, ...restProps } = props
  return (
    <CircularProgress
      {...restProps}
      size={24}
      sx={{
        color: green[500],
        position: sx?.position ?? 'absolute',
        top: '50%',
        left: '50%',
        marginTop: sx?.marginTop ?? '-12px',
        marginLeft: sx?.marginLeft ?? '-12px',
      }}
    />
  )
}

export {
  TextInput, SearchInput, EmailInput, SelectInput, DateInput, TimeInput, AutocompleteInput, CheckboxInput,
  DateTimeInput, SwitchInput, AntSwitch, TextareaInput, RadioButtonsGroup, Spinner
}
