import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import SettingsIcon from '@mui/icons-material/Settings'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Divider from '@mui/material/Divider'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth, useClient } from '../context/auth-context'
//import RouteIcon from '@mui/icons-material/Route';
import { Tooltip } from '@mui/material'
import { BlockWhileProgress } from '../components/loading-progress'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import { useSelectionContextState, useSelectionContextUpdater } from '../context/selection-context'
import { Spinner } from './form-components'
import { getCarTitle } from '../utils/textFormating'
import { Link } from 'react-router-dom'
import { MenuItem as MuiMenuItem, Button, styled } from '@mui/material'
import { NotificationShortText } from './reports/notifications/NotificationText'
import { useTranslation } from 'react-i18next'
//import { styled } from '@mui/system';

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  '&:nth-of-type(even)': {
    // your style here },
    backgroundColor: theme.palette.tableRow.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.tableRow.hover,
  },
}))

function NotificationMenu({ anchorEl, isMenuOpen, handleMenuClose }) {
  const [isLoading, setIsLoading] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [carsDict, setCarsDict] = useState({})
  const client = useClient()
  const { cars } = useSelectionContextState()
  const { t } = useTranslation('translation', { keyPrefix: 'otherComponents' })

  useEffect(() => {
    const tempDict = Object.assign({}, ...cars?.map((c) => ({ [c.id]: { ...c } })))
    setCarsDict(tempDict)
  }, [cars])

  useEffect(() => {
    setIsLoading(true)
    client('notifications/load-list', { data: { limit: 10 } })
      .then((res) => {
        if (res.error) {
          //setErrors(old=>{return{...old,...res.error}});
        } else if (res?.result) {
          setNotifications(res.result)
        } else {
          //ToDo errors
        }
      })
      .finally(() => setIsLoading(false))
  }, [client])

  return isLoading ? (
    <Spinner />
  ) : (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id="notifications-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
    >
      {notifications?.map((n, idx) => (
        <MenuItem key={`notif-${idx}`} component={Link} to={`reports/notifications/${n?.id}`}>
          <NotificationShortText data={n} object={getCarTitle(carsDict[n?.id_car])} />
        </MenuItem>
      ))}
      {notifications?.length > 9 ? (
        <MenuItem component={Link} to={`reports/notifications`}>
          {t('seeMore')}
        </MenuItem>
      ) : null}
    </Menu>
  )
}

function MainAppBar() {
  const [anchorEl, setAnchorEl] = useState({})
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(null)
  let navigate = useNavigate()
  const { pathname } = useLocation()
  const { user, logout } = useAuth()
  const { doRefresh } = useSelectionContextUpdater()
  const client = useClient()
  const { t } = useTranslation('translation', { keyPrefix: 'otherComponents' })

  //ToDo read from organization table and take into consideration subscription features
  //const hasSetRoute = user?.type==='superadmin' || user?.type=== 'manager' || user?.type === 'admin'
  const isMenuOpen = Boolean(anchorEl?.anchor)

  useEffect(() => {
    let timeoutID
    if (user?.type && user.type !== 'limiteduser' && !user?.pending_2fa) {
      //console.log('unread count user: ', user)
      function getNotifications() {
        client('notifications/unread-count')
          .then((res) => {
            if (res.error) {
              //setErrors({...errors,...res.error});
            } else if (res.done === 'OK') {
              setUnreadNotificationCount(res?.result ?? 0)
            } else {
              //ToDo errors
            }
          })
          .finally(() => {
            timeoutID = setTimeout(getNotifications, 60000)
          })
      }

      clearTimeout(timeoutID)
      getNotifications()
    }

    return () => {
      clearTimeout(timeoutID)
    }
  }, [client, user])

  function handleSettingsOpen() {
    if (pathname === '/set-route') doRefresh('/main')
    navigate('/main/settings/account')
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl({ type: 'profile', anchor: event.currentTarget })
  }

  const handleNotificationsMenuOpen = (event) => {
    setAnchorEl({ type: 'notifications', anchor: event.currentTarget })
    setUnreadNotificationCount(null)
  }

  const handleMenuClose = () => {
    setAnchorEl({})
  }

  const renderMenu =
    (anchorEl?.type === 'profile' && (
      <Menu
        anchorEl={anchorEl?.anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id="profile-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem disabled>{user?.user}</MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            logout()
            setAnchorEl({})
            navigate('/login')
          }}
        >
          {t('logout')}
        </MenuItem>
      </Menu>
    )) ||
    (anchorEl?.type === 'notifications' && (
      <NotificationMenu anchorEl={anchorEl?.anchor} isMenuOpen={isMenuOpen} handleMenuClose={handleMenuClose} />
    ))

  function handleNavigate(url) {
    doRefresh(url)
    navigate(url)
  }

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <AppBar position="static">
        <Toolbar>
          <Button color="inherit" onClick={() => handleNavigate('/main')}>
            {t('siteName')}
          </Button>
          {/*<Tabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            {filterButtons.map((button, idx) => (
              <Button
                key={idx}
                color="inherit"
                variant="outlined"
                sx={{ ml: 1, minWidth: 'unset' }}
                onClick={() => {
                  let temp = [ ...filterButtons ]
                  temp[idx] = { ...button, selected: button.selected ? false : true }

                  setFilterButtons(temp)
                }}
              >
                {button.label}
              </Button>
            ))}
          </Tabs>
          */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            {user?.id && user.type !== 'limiteduser' && !user?.pending_2fa && (
              <Tooltip title={t('notifications')}>
                <IconButton size="large" color="inherit" onClick={handleNotificationsMenuOpen}>
                  <Badge badgeContent={unreadNotificationCount} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            {/* {hasSetRoute && pathname !== '/set-route' && (
              <Tooltip title="Set route">
                <IconButton size="large" color="inherit" onClick={() => handleNavigate('/set-route')}>
                  <RouteIcon />
                </IconButton>
              </Tooltip>
            )} */}
            {(user?.type === 'superadmin' || user?.type === 'manager' || user?.type === 'admin') &&
              !user?.pending_2fa &&
              !pathname.startsWith('/reports') && (
                <Tooltip title={t('routeReorts')}>
                  <IconButton size="large" color="inherit" onClick={() => navigate('/reports/route-reports')}>
                    <LibraryBooksIcon />
                  </IconButton>
                </Tooltip>
              )}
            {(user?.type === 'superadmin' || user?.type === 'manager') &&
              pathname !== '/management' &&
              !user?.pending_2fa && (
                <Tooltip title={t('management')}>
                  <IconButton size="large" color="inherit" onClick={() => navigate('/management')}>
                    <EmojiTransportationIcon />
                  </IconButton>
                </Tooltip>
              )}
            {/* <Tooltip title="Contact">
              <IconButton size="large" color="inherit">
                <PhoneIcon />
              </IconButton>
            </Tooltip> */}
            {user?.id && !user?.pending_2fa && (
              <Tooltip title={t('settings')}>
                <IconButton size="large" color="inherit" onClick={handleSettingsOpen}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            )}
            {user?.id && !user?.pending_2fa && (
              <Tooltip title={t('user')}>
                <IconButton size="large" edge="end" onClick={handleProfileMenuOpen} color="inherit">
                  <AccountCircle />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {renderMenu}
        </Toolbar>
      </AppBar>
      <BlockWhileProgress />
    </Box>
  )
}

export default MainAppBar
