import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useSelectionContextState } from '../context/selection-context'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function SnackBar() {
  const { snackBarData } = useSelectionContextState()
  const [open, setOpen] = React.useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  React.useEffect(() => {
    if (snackBarData && snackBarData?.type) setOpen(true)
  }, [snackBarData])

  return (
    <Snackbar
      open={open}
      autoHideDuration={snackBarData?.autoHideDuration !== null ? snackBarData?.autoHideDuration ?? 6000 : null}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={snackBarData?.type} sx={{ width: '100%' }}>
        {snackBarData?.message}
      </Alert>
    </Snackbar>
  )
}
