import React from 'react'
//import {queryCache} from 'react-query'
import { QueryCache } from 'react-query'
import * as auth from '../utils/auth'
import { client, fileClient } from '../utils/api-client'
import { useAsync } from '../utils/hooks'
import i18next from 'i18next'
//import {FullPageSpinner, FullPageErrorFallback} from 'components/lib'

async function bootstrapAppData() {
  let user = null

  const token = await auth.getToken()
  if (token) {
    const data = await client('bootstrap', { token })
    //console.log("bootstrap data : ", data);
    // QueryCache.setQueryData('list-items', data.listItems, {
    //   staleTime: 5000,
    // })
    user = data?.result
  }
  return user
}

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const { data: user, status, error, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync()

  React.useEffect(() => {
    const appDataPromise = bootstrapAppData()
    run(appDataPromise)
  }, [run])

  const login = React.useCallback(
    (form) =>
      auth.login(form).then((response) => {
        setData(response)
        return response
      }),
    [setData],
  )

  const register = React.useCallback(
    (form) =>
      auth.register(form).then(
        (response) => {
          setData(response.result)
          return response.result
        } /*setData(user)*/,
      ),
    [setData],
  )

  //  const register = React.useCallback(
  //    form =>
  //      auth.register(form).then(
  //        user => {
  //          console.log('Registration', user)
  //          return user
  //        } /*setData(user)*/,
  //        ),
  //        [setData],
  //      )

  const reauthenticate = React.useCallback(() => {
    const appDataPromise = bootstrapAppData()
    return run(appDataPromise)
  }, [run])

  const logout = React.useCallback(() => {
    auth.logout().finally(() => {
      const queryCache = new QueryCache({
        onError: (error) => {
          console.log(error)
        },
      })
      queryCache.clear()
      setData(null)
    })
  }, [setData])

  const value = React.useMemo(
    () => ({ user, login, logout, register, reauthenticate }),
    [login, logout, register, user, reauthenticate],
  )

  if (isLoading || isIdle) {
    //return <FullPageSpinner />
    return <h1>Loading...</h1>
  }

  if (isError) {
    console.log('error : ', error)
    //return <FullPageErrorFallback error={error} />
    return <h1>Loading error... {error}</h1>
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`${i18next.t('contexts.err.unhandledStatus')} ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(i18next.t('contexts.err.useAuthMustBeWithinAuthProvider'))
  }
  return context
}

function useClient() {
  const { user } = useAuth()
  const token = user?.token
  return React.useCallback((endpoint, config, type) => client(endpoint, { ...config, token }, type), [token])
}

function useFileClient() {
  const { user } = useAuth()
  const token = user?.token
  return React.useCallback((endpoint, config, type) => fileClient(endpoint, { ...config, token }, type), [token])
}

async function simpleClient(endpoint, config) {
  const token = await auth.getToken()

  if (token) {
    const data = await client(endpoint, { ...config, token })
    console.log('simpleClient data : ', data)
    return data
  }
  return { error: i18next.t('contexts.err.missingAutentification') }
}

export { AuthProvider, useAuth, useClient, simpleClient, useFileClient }
