import React from 'react'
import './App.css';
import Version from './components/version';
import {useAuth} from './context/auth-context'
import 'leaflet/dist/leaflet.css';
import SnackBar from './components/SnackBar';
import MainAppBar from './components/main-app-bar';
import Pending2FARoutes from './routes/pending2FARoutes';

 const SuperAdminRoutes = React.lazy(() => import('./routes/superadmin_routes'))
 const AdminRoutes = React.lazy(() => import('./routes/admin_routes'))
 const UserRoutes = React.lazy(() => import('./routes/user_routes'))
 const LimitedUserRoutes = React.lazy(() => import('./routes/limited_user_routes'));
 const UnauthenticatedRoutes = React.lazy(() => import('./routes/unauthenticated_routes'))
 const OtherRoutes = React.lazy(() => import('./routes/other_routes'))
 

function App() {
  let {user} = useAuth();
  console.log('user: ', user)

  let component;
  if (user && user.type) {
    if(user.pending_2fa) component = <Pending2FARoutes/>;
    else if (user.type === 'superadmin') component = <SuperAdminRoutes/>
    else if (user.type === 'admin') component = <AdminRoutes/>;
    else if (user.type === 'user') component = <UserRoutes/>;
    else if (user.type === 'limiteduser') component = <LimitedUserRoutes/>;
    else component = <OtherRoutes/>
  } else {
    component = <UnauthenticatedRoutes />;
  }

  return (
    <>
      <React.Suspense fallback={<h1>Loading...</h1>}>
        <MainAppBar />
        {component}
      </React.Suspense>
      <Version />
      <SnackBar />
    </>
  )
}

export default App
